
import getExchangeMarzs from '../../../common/js/get-exchange-marzs/get-exchange-marzs.js';
export default {
	name: 'exchange-marzs',
	data: () => ({
		ecbDifference: null
	}),
	async created() {
		this.ecbDifference = await getExchangeMarzs();
	}
};
