import { defineComponent } from 'vue';
import { useMasdPersonalization } from '../../common/js/composables/personalization';


export default {
  __name: 'masd',
  props: {
	personalized: {
		default: false
	},
	defaultId: {
		required: false
	},
	moduleId: {
		required: false
	}
},
  setup(__props) {

const props = __props


const { masdFragments, needsMasdPlaceholder, dataAttributes } = useMasdPersonalization(props);

const dynamicComponent = (template) => {
	return defineComponent({
		template
	});
};

return { __sfc: true,props, masdFragments, needsMasdPlaceholder, dataAttributes, dynamicComponent, defineComponent, useMasdPersonalization }
}

}