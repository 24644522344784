import { useMherPersonalization } from '../../common/js/composables/personalization';


export default {
  __name: 'mher',
  props: {
	defaultId: {
		required: true
	},
	personalized: {
		default: false
	},
	moduleId: {
		required: false
	}
},
  setup(__props) {

const props = __props


const { mherFragment, needsMherPlaceholder, dataAttributes } = useMherPersonalization(props);

return { __sfc: true,props, mherFragment, needsMherPlaceholder, dataAttributes, useMherPersonalization }
}

}