
import tabs from '../tabs/tabs.vue';
import videoPlayer from '../video-player/video-player.vue';

export default {
	extends: tabs,
	data() {
		return {
			selectedVideoId: 0,
			modalOpened: false
		};
	},
	props: {
		videos: {
			required: true
		},
		swImagePath: {
			required: true
		},
		videoPlayerInside: {
			required: true
		},
		videoId: {},
		modalId: {
			required: true
		},
		recommendedVideos: {},
		recommendedTitle: {},
		recommendedTitleSrOnly: {},
		playlistTitle: {},
		fulltextLinkTitle: {},
		fulltextCloseLinkTitle: {},
		hasPreviewImage: {
			default: true
		},
		ariaLabel: {},
		gtm: {
			required: false
		}
	},
	components: {
		videoPlayer
	},
	methods: {
		selectVideo(event) {
			this.selectedVideoId = this.selectedTabIndex;
			if (!this.videoPlayerInside) {
				this.$emit('on-select-video', this.videoId);
			}
			this.$openModal(event, this.modalId);
		}
	},
	created() {
		this.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == this.modalId) {
				this.modalOpened = false;
				this.$refs.video.focus();
			}
		});
		this.$modalEventBus.$on('modal-opened', (modalId) => {
			if (modalId == this.modalId) {
				this.modalOpened = true;
			}
		});
	}
};
