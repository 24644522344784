
import { required } from 'vuelidate/lib/validators';

const GTM_TYPES = {
	GMP: 'gmp'
};

export default {
	data() {
		return {
			showPlayer: false,
			autoplay: 0,
			initLoad: true,
			showVideo: false
		};
	},
	components: {},
	props: {
		video: {
			required
		},
		modalId: {},
		gtm: {
			required: false
		}
	},
	computed: {
		videoTitle() {
			return this.$refs['youtube-player'].getVideoData().title;
		},
		start() {
			return this.video.start;
		}
	},
	watch: {
		video() {
			this.showPlayer = false;
			this.showVideo = false;
			this.$nextTick(() => {
				this.showPlayer = true;
			});
		}
	},
	methods: {
		videoPlayingEnded() {
			this.autoplay = 1;
			this.$emit('on-play-next-video');
			if (this.gtm && this.gtm == GTM_TYPES.GMP) {
				this.sendGTM('complete', 100);
			}
		},
		videoPlayerLoaded() {
			this.showVideo = true;
			// akadálymentes móka
			if (this.$refs['youtube-player'] && this.$refs['youtube-player'].$el && this.$refs['youtube-player'].$el.querySelector('iframe')) {
				let iframeElem = this.$refs['youtube-player'].$el.querySelector('iframe');
				iframeElem.setAttribute('name', 'Youtube videó');
				if (!this.initLoad) {
					iframeElem.focus();
				}
				this.initLoad = false;
			}
		},
		videoPlayerPaused(e) {
			if (this.gtm && this.gtm == GTM_TYPES.GMP) {
				const percent = Math.round((e.target.playerInfo.currentTime / e.target.getDuration()) * 100);
				this.sendGTM('progress', percent);
			}
		},
		videoPlayerPlaying(e) {
			if (this.gtm && this.gtm == GTM_TYPES.GMP) {
				const percent = Math.round((e.target.playerInfo.currentTime / e.target.getDuration()) * 100);
				const status = percent == 0 ? 'start' : 'progress';
				this.sendGTM(status, percent);
			}
		},
		sendGTM(status, percent) {
			this.$gtm.trackEvent({
				event: 'videoInteraction',
				title: this.video.title,
				status,
				percent
			});
		}
	},
	mounted() {
		this.showPlayer = true;
		this.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == this.modalId) {
				this.autoplay = 0;
				this.showPlayer = false;
			}
		});
		this.$modalEventBus.$on('modal-opened', (modalId) => {
			if (modalId == this.modalId) {
				this.showPlayer = true;
				this.initLoad = true;
			}
		});
	}
};
