
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			firstVisibleVideoIndex: 0
		};
	},
	props: {
		videos: {
			required
		},
		swImagePath: {
			required
		},
		recommendedTitle: {
			required
		},
		recommendedTitleSrOnly: {
			required
		},
		autoPlayVideoIndex: {
			required
		},
		autoplay: {
			required
		}
	},
	methods: {
		selectVideo(index) {
			this.setFirstVisibleVideoIndex(index);
			this.$emit('on-select-recommended-video', index);
		},
		nextStep() {
			++this.firstVisibleVideoIndex;
		},
		previousStep() {
			--this.firstVisibleVideoIndex;
		},
		setFirstVisibleVideoIndex(index) {
			if (this.isMobile || index == this.videos.length - 1 || this.autoplay) {
				this.firstVisibleVideoIndex = index == this.videos.length - 1 ? index - 1 : index;
			}
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		},
		isPreviousButtonDisabled() {
			return this.firstVisibleVideoIndex == 0;
		},
		isNextVideoButtonDisabled() {
			return this.firstVisibleVideoIndex == this.videos.length - 2;
		}
	},
	watch: {
		autoPlayVideoIndex(index) {
			this.setFirstVisibleVideoIndex(index);
		}
	}
};
