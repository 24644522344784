
import { required } from 'vuelidate/lib/validators';
import { VueAccordion, VueAccordionItem } from '@ui-common/vue-accordion';

export default {
	data() {
		return {};
	},
	props: {
		videos: {
			required
		},
		selectedVideoIndex: {
			required
		},
		playlistTitle: {
			required
		}
	},
	methods: {
		selectVideo(index) {
			this.$emit('on-video-select', index);
		},
		scrollPlaylist(index) {
			if (!this.isMobile) {
				let elem = this.$refs['video-playlist-list'].querySelectorAll('li')[index];
				this.$scrollTo(elem, { duration: 500, container: this.$refs['video-playlist'] });
			}
		}
	},
	computed: {
		isMobile() {
			return this.$resize && this.$mq.below(767);
		}
	},
	components: {
		VueAccordion,
		VueAccordionItem
	},
	watch: {
		selectedVideoIndex(value) {
			this.scrollPlaylist(value);
		}
	}
};
