
import VueButton from '@ui-common/vue-button';
import messages from './accept-pdf-modal.lang.json';
import { defineAsyncComponent } from 'vue';

export default {
	i18n: {
		messages
	},
	components: {
		VueButton,
		VuePdf: defineAsyncComponent(() => import(/* webpackChunkName: "portal/chunks/vue-pdf" */ 'vue-pdf'))
	},
	props: {
		value: {
			required: true
		},
		id: {
			required: true
		},
		pdfUrl: {
			required: true,
			type: String
		}
	},
	emits: ['input'],
	data: () => ({
		src: null,
		numPages: undefined,
		accepted: false
	}),
	computed: {
		modalId() {
			return `accept-pdf-modal-${this.id}`;
		},
		documentTitle() {
			// eslint-disable-next-line vue/require-slots-as-functions
			return this.$slots.title[0].text;
		}
	},
	watch: {
		value: {
			handler(val) {
				this.accepted = val;
			},
			immediate: true
		},
		accepted(val) {
			this.$emit('input', val);
		}
	},
	methods: {
		accept() {
			this.accepted = true;
		},
		async loadPdf(e) {
			const isPdfUnloaded = !this.src || this.src.destroyed;
			if (isPdfUnloaded) {
				const vuePdf = await import(/* webpackChunkName: "portal/chunks/vue-pdf" */ 'vue-pdf');
				const loadingTask = vuePdf.default.createLoadingTask(this.pdfUrl);
				this.src = loadingTask;
				this.src.promise.then((doc) => {
					this.numPages = doc.numPages;
					this.src._worker.destroy();
					this.$openModal(e, this.modalId);
				});
			}
		}
	}
};
