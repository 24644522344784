import { render, staticRenderFns } from "./masd.vue?vue&type=template&id=0b9938f4&lang=pug&"
import script from "./masd.vue?vue&type=script&setup=true&lang=js&"
export * from "./masd.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./masd.vue?vue&type=style&index=0&id=0b9938f4&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports