
export default {
	props: {
		loadFonts: {
			required: false,
			default: true
		},
		searchPageUrl: {
			requied: true,
			default: '/portal/hu/fiok-atm-kereso'
		},
		searchType: {
			required: false
		}
	}
};
