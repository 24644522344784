
import AcceptPdfModal from '../../components/accept-pdf-modal/accept-pdf-modal';
import { DISPLAY_DATE_FORMAT } from '../../js/constants/constants';
import { mapState } from 'vuex';

const TYPES = {
	ARCHIVE: 'ARCHIVE',
	FUTURE: 'FUTURE',
	ACTUAL: 'ACTUAL',
	ACTUAL_FUTURE: 'ACTUAL_FUTURE',
	ACTUAL_ARCHIVE_FUTURE: 'ACTUAL_ARCHIVE_FUTURE'
};
export default {
	data() {
		return {
			loaded: false,
			modelValue: false
		};
	},
	components: {
		AcceptPdfModal
	},
	props: {
		blockId: {
			required: true,
			type: String
		},
		text: {
			required: false,
			type: String
		},
		value: {
			required: false,
			type: Boolean
		},
		hideDate: {
			required: false,
			type: Boolean
		},
		hideBullet: {
			required: false,
			type: Boolean
		},
		inline: {
			type: Boolean,
			default: false
		},
		type: {
			required: true,
			validator(value) {
				return Object.values(TYPES).includes(value);
			}
		},
		target: {
			required: false,
			type: String,
			default: '_blank'
		},
		acceptanceNeeded: {
			required: false,
			type: Boolean
		}
	},
	watch: {
		announcements: {
			handler(val) {
				if (val && val.length) {
					this.loaded = true;
				}
			},
			immediate: true
		},
		modelValue: {
			handler(val) {
				this.$emit('input', val);
			},
			immediate: true
		}
	},
	computed: {
		...mapState('announcements', ['announcements']),
		displayDateFormat() {
			return DISPLAY_DATE_FORMAT[this.$i18n.locale];
		},
		filteredAnnouncements() {
			const myAnnouncement = this.announcements.find((announcement) => announcement.blockId == this.blockId);
			return myAnnouncement ? myAnnouncement.result : null;
		}
	}
};
