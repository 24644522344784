
import videoPlayerYoutube from './video-player-youtube/video-player-youtube.vue';
import videoPlayerPlaylist from './video-player-playlist/video-player-playlist.vue';
import videoPlayerRecommended from './video-player-recommended/video-player-recommended.vue';
import videoPlayerFullText from './video-player-full-text/video-player-full-text.vue';
import { required } from 'vuelidate/lib/validators';

export default {
	data() {
		return {
			fullTextVisible: false,
			selectedVideoIndex: 0,
			recommendedVideoSelected: false,
			selectedRecommendedVideoIndex: 0,
			autoplay: false
		};
	},
	props: {
		videos: {
			required
		},
		recommendedVideos: {
			default: []
		},
		swImagePath: {
			required
		},
		startIndex: {
			default: 0
		},
		modalId: {},
		recommendedTitle: {
			required
		},
		recommendedTitleSrOnly: {
			required
		},
		playlistTitle: {
			required
		},
		fulltextLinkTitle: {},
		fulltextCloseLinkTitle: {},
		fullTextMode: {
			required
		},
		gtm: {
			required: false
		}
	},
	components: {
		videoPlayerYoutube,
		videoPlayerPlaylist,
		videoPlayerRecommended,
		videoPlayerFullText
	},
	watch: {
		startIndex(value) {
			this.selectedVideoIndex = value;
		}
	},
	computed: {
		recommendedMode() {
			return this.recommendedVideos.length !== 0;
		},
		singleVideoMode() {
			return !this.recommendedMode && this.videos.length === 1;
		},
		playlistMode() {
			return !this.recommendedMode && !this.singleVideoMode;
		},
		currentVideo() {
			return this.recommendedVideoSelected ? this.recommendedVideos[this.selectedRecommendedVideoIndex] : this.videos[this.selectedVideoIndex];
		},
		videoFullText() {
			return this.recommendedVideoSelected
				? this.recommendedVideos[this.selectedRecommendedVideoIndex].fullText
				: this.videos[this.selectedVideoIndex].fullText;
		}
	},
	methods: {
		changeCurrentVideo(index) {
			this.autoplay = false;
			this.selectedVideoIndex = index;
		},
		playNextVideo() {
			if (this.recommendedVideoSelected) {
				this.selectedRecommendedVideoIndex = ++this.selectedRecommendedVideoIndex % this.recommendedVideos.length;
			} else {
				this.selectedVideoIndex = ++this.selectedVideoIndex % this.videos.length;
			}
			this.autoplay = true;
		},
		playRecommendedVideo(index) {
			this.autoplay = false;
			this.recommendedVideoSelected = true;
			this.selectedRecommendedVideoIndex = index;
		},
		showFulltext() {
			this.fullTextVisible = true;
			this.$focusModalTop(this.modalId);
		},
		hideFulltext() {
			this.fullTextVisible = false;
			this.$focusModalTop(this.modalId);
		}
	},
	mounted() {
		this.$modalEventBus.$on('modal-closed', (modalId) => {
			if (modalId == this.modalId) {
				this.recommendedVideoSelected = false;
				this.fullTextVisible = false;
				this.selectedRecommendedVideoIndex = 0;
			}
		});
		this.selectedVideoIndex = 0;
	}
};
